/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.MiniCartItem {
    &-Wrapper {
        display: grid;
        grid-template-columns: 100px calc(100% - 100px);
        margin: 0 0 10px;
        padding: 20px;
        background-color: var(--color-white);
        text-align: start;
        @include border-radius(8px);

        &_isAddedOrUpdated {
            border: var(--emphasis-border);
        }

        .CartItem {
            &-Title {
                width: 100%;
                margin-block-end: 4px;

                * {
                    font-size: 17px;
                    line-height: 22px;
                    font-weight: 500;
                }
            }

            &-Heading {
                overflow: unset;
                text-overflow: unset;
                white-space: unset;
            }

            &-Price, &-Price * {
                display: initial;
                font-size: 15px;
                line-height: 20px;
                font-weight: 400;
            }

            &-PriceLabel {
                display: none;
            }

            &-DeleteButtonText {
                display: initial;
                font-size: 15px;
                line-height: 15px;
                font-weight: 400;
            }

            &-Delete:hover {
                color: var(--link-hover);
            }

            &-QuantityWrapper {
                .Field-Wrapper_type_number {
                    @include field-type-number-small;
                }

                .Field_type_number {
                    margin-block-start: 0;
                }
            }

            &-CustomAssortmentInfoWrapper {
                margin-block-start: 0;
            }

            &-CustomAssortmentInfo {
                margin-block: 0;

                & > * {
                    font-size: 15px;
                    line-height: 20px;
                }
            }
        }

        .CustomAssortmentItem {
            &-CaseQty {
                font-weight: 500;
                margin-block-end: 0;
            }

            &-EditLinkWrapper {
                margin-block-end: 0;
            }

            &-EditLink {
                font-size: var(--paragraph-font-size);
                color: var(--link-hover);
                cursor: pointer;
            }
        }

        .CloseIcon {
            display: none;
        }
    }

    &-ImageWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        grid-row: span 2;

        > *, .Image {
            max-width: 80px;
        }

        .CartItem {
            &-Link {
                display: block;
            }
        }
    }

    &-PriceWrapper {
        margin-block-end: 10px;
    }

    &-ContentWrapper {
        * {
            text-align: start;
        }
    }

    &-ActionsWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &-BulkProductCaseInfo, &-BulkProductCaseInfo * {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
    }
}

@include mobile {
    .MiniCartItem {
        &-Wrapper {
            padding: 10px;
        }
    }
}
