/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.CartPageOrderSummary {
    .CheckoutOrderSummary {
        &-Header {
            h2 {
                @include h3-style;
            }
        }

        &-ButtonWrapper {
            inset-block-end: 0;

            .hideOnScroll & {
                transform: unset;
            }
        }

        &-SummaryItem {
            &:first-child {
                padding-block-start: 15px;
            }

            &:last-child {
                padding-block-end: 15px;
            }

            &_isTotal {
                border-block-start: none;
            }
        }
    }
}

@include desktop {
    .CartPageOrderSummary {
        &.CheckoutOrderSummary {
            margin-block-end: 0;
            padding: 0;
        }

        .CheckoutOrderSummary {
            &-OrderTotals {
                border-block-start: 1px solid var(--primary-divider-color);
            }
        }
    }
}

@include mobile {
    .CartPageOrderSummary {
        .CheckoutOrderSummary {
            &-Header {
                display: block;
            }

            &-ButtonWrapper {
                height: auto;
                padding: 20px;
            }
        }
    }
}