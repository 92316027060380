/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.CartPage {
    &-TableHead {
        display: none;
    }

    &-Heading {
        @include h2-style;
    }

    &-Items {
        .CartItem {
            margin-block-end: 10px;
            border-bottom: none;

            &-Heading {
                max-width: unset;
                text-overflow: initial;
                overflow: initial;
                white-space: initial;
            }

            &-Qty {
                [dir="ltr"] & {
                    @include field-type-number;
                    @include field-type-number-small;
                }
            }

            &-SkuWrapper {
                * {
                    text-transform: uppercase;
                }
            }

            &-Price {
                display: block;

                * {
                    font-weight: 500;
                }
            }

            &-Delete {
                &:hover, &:focus {
                    color: var(--link-hover);
                }
            }
        }
    }

    &-Empty {
        + .CartPage-Floating {
            display: none;
        }
    }
}

@include desktop {
    .CartPage {
        &-Wrapper {
            padding-inline: 15px;
            grid-template-columns: 1fr 30%;
            grid-column-gap: 30px;
        }

        &-Heading {
            grid-column: span 2;
        }

        &-Floating {
            min-width: 340px;
            margin-block-start: 0;
            border: none;

            > * {
                @include border-radius(8px);
                margin: 0 0 30px;
                padding: 30px;
                background-color: var(--color-white);
                order: 2;

                &:last-child {
                    margin-block-end: 0;
                }
            }

            > .CmsBlock-Wrapper {
                p:last-child {
                    margin-block-end: 0;
                }
            }
        }

        &-Summary {
            order: 1;

            h3 {
                margin: 20px 0 -8px;
                font-size: 17px;
                line-height: 23px;
            }
        }

        &-CheckoutButton {
            margin: 0;
        }

        &-Items {
            .CartItem {
                &-Wrapper {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 30px;
                    @include border-radius(8px);
                }

                &-ImageWrapper {
                    width: 96px;
                }

                &-ItemInfoWrapper {
                    display: grid;
                    grid-template-columns: repeat(12, 1fr);
                    width: 100%;
                }

                &-ProductInfo {
                    display: block;
                    grid-column: span 10;
                }

                &-Subtotal {
                    grid-column: span 2;
                }

                &-Actions {
                    grid-column: span 12;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                }

                &-Title {
                    width: unset;
                    padding-inline-start: 0;
                }

                &-PriceWrapper {
                    text-align: end;
                }

                &-PriceLabel {
                    line-height: var(--paragraph-font-size);
                }

                &-DeleteButtonText {
                    margin: 0;
                    padding: 0;
                    line-height: var(--paragraph-font-size);
                }

                &-Heading {
                    margin: 0;

                    * {
                        @include paragraph-style-desktop;
                        font-weight: 500;
                    }

                    > a {
                        font-weight: 500;
                        line-height: var(--paragraph-font-size);
                    }
                }

                &-Options {
                    display: none;
                }

                &-QuantityWrapper {
                    margin-block-start: 15px;
                }

                &-Link {
                    &:hover, &:focus {
                        color: var(--link-hover);
                    }
                }

                &-SkuWrapper {
                    margin: 0 0 10px;

                    * {
                        font-size: 12px;
                        line-height: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@include mobile {
    .CartPage {
        padding-block-end: 0;
        margin-block-end: 30px;

        &-Heading {
            display: block;
            margin: 0;
            padding: 20px 0;
        }

        &-Summary {
            @include background-full-width;
            padding-block: 20px;

            h3 {
                margin: 30px 0 0;
                font-size: 15px;
                line-height: 20px;
            }

            .Field {
                margin: 20px 0 0;
            }
        }

        .CartItem {
            @include background-full-width(var(--color-white));

            &-Wrapper {
                display: block;
                grid-gap: unset;
                grid-template-columns: unset;
            }

            &-Row {
                display: flex;

                &:nth-child(3) {
                    margin: 10px 0 15px;
                }

                &:nth-child(4) {
                    align-items: flex-end;
                    justify-content: space-between;
                }
            }

            &-ImageWrapper {
                width: 90px;
                margin: 0;
                padding: 0;

                .CartItem-Link {
                    display: block;
                    height: 100%;
                    width: 100%;
                }

                .Image {
                    width: 90px;
                }
            }

            &-ProductNameWrapper {
                width: calc(100% - 90px);
                padding-inline-start: 10px;
            }

            &-Title {
                width: 100%;
                margin-block-end: 0;
            }

            &-Heading {
                margin: -3px 0 0;

                > a {
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 500;
                }
            }

            &-SkuLabel, &-Sku {
                font-size: 10px;
                line-height: 14px;
                font-weight: 500;
            }

            &-ProductInfo {
                display: block;
            }

            &-DeleteButtonText_isMobileLayout {
                display: block;
            }

            &-PriceWrapper, &-PriceWrapper * {
                text-align: start;
            }

            &-Price_isMobileLayout {
                align-items: flex-start;
                line-height: 15px;

                * {
                    line-height: 15px;
                }
            }

            &-PriceLabel {
                line-height: var(--paragraph-font-size-mobile);
            }
        }
    }
}