/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

$minicart-additional-height: 164px;

.MiniCart {
    &-Wrapper {
        background-color: var(--color-gray);
        cursor: initial;

        @include desktop {
            max-width: 600px;
        }

        > .bm-menu {
            background-color: transparent;

            .bm-item-list {
                padding: 0;
            }
        }

        .CartItem {
            border-bottom: none;

            &-Wrapper {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin-block-end: 7px;
                padding: 16px 18px 15px;

                @include border-radius(8px);
            }

            &-Heading {
                font-size: var(--paragraph-font-size);
                font-weight: 500;
            }

            &-Picture {
                width: 96px;
                height: 96px;
            }
        }

        .CartOverlay {
            &-Items {
                max-height: unset;
            }

            &-Additional {
                position: absolute;
                inset-block-end: 0;
                width: 100%;
                height: $minicart-additional-height;
                padding: 30px;
                background-color: var(--color-white);
                z-index: 10;

                @include box-shadow(0px -1px 6px rgba(0, 0, 0, 0.25));

                .CartOverlay {
                    &-Tax, &-Total, &-Actions {
                        background-color: transparent;
                        padding: 0
                    }

                    &-Actions {
                        display: grid;
                        grid-template-columns: repeat(12, 1fr);
                        grid-gap: 10px;
                    }

                    &-CartButton, &-CheckoutButton {
                        margin: 0;
                    }

                    &-CartButton {
                        grid-column: span 5;
                        display: inline-flex;

                        @include button-hollow-orange;
                    }

                    &-CheckoutButton {
                        grid-column: span 7;
                    }
                }
            }
        }
    }

    &-Header {
        background-color: var(--color-white);
        border-bottom: none;
    }

    &-ContentWrapper {
        height: calc(100vh - 106px - #{$minicart-additional-height} - 20px);
        overflow-y: auto;
        padding: 20px 0 10px;
    }

    &-SubtotalWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 30px;
    }

    &-Subtotal {
        font-weight: 500;
    }

    &-ItemsQty {
        font-weight: 500;
    }
}
